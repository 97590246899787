import React from 'react';
import { drawerSelectedColor } from '../../constant/colors';
import { Breadcrumbs, Typography } from '@mui/material';

const styles = {
  title: {
    fontSize: 18,
  },
  blue: {
    color: drawerSelectedColor,
    cursor: 'pointer',
    fontSize: 18,
  },
};

const Bread = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" separator=">">
        {data.length &&
          data.map((d, i) => {
            if (data.length - 1 === i) {
              return (
                <Typography 
                key={`finaltitle`} 
                // className={classes.title} 
                style={d.style || styles.title}>
                  {d.title}
                </Typography>
              );
            } else {
              return (
                <Typography
                  key={`Brad${i}`}
                  // className={`${classes.blue} ${classes.title}`}
                  onClick={d.onClick}
                  style={d.style || styles.blue}
                >
                  {d.title}
                </Typography>
              );
            }
          })}
      </Breadcrumbs>
    </div>
  );
};

export default Bread;
