import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import INTERNALROUTES from "../constant/internalRoutes";
import { useLocation, useNavigate } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LeakAddOutlinedIcon from '@mui/icons-material/LeakAddOutlined';
import ViewArrayOutlinedIcon from '@mui/icons-material/ViewArrayOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

import { drawerSelectedColor, drawerSelectedItem } from "../constant/colors";
import { useState } from "react";

const styles = {
  drawerContentDiv: {
    paddingTop: 10,
    flexGrow: 1
  },
  root: {
    width: "100%",
    // color: whiteColor,
  },
  iconColor: {
    // color: whiteColor,
    minWidth: "35px",
  },
  fontStyle: {
    fontSize: 14,
  },
  subHeaderStyle: {
    color: "inherit",
    backgroundColor: "inherit",
    fontWeight: 400,
    fontSize: "0.875rem",
  },
};

const DrawerContents = (props) => {

  const [selectedIndex, setSelectedIndex] = useState(null);

  const navigate = useNavigate(); // navigate for navigation
  const location = useLocation(); // location for access current location

  const selectedStyle = (path) => ({
    borderRadius: 30,
    backgroundColor: location.pathname.includes(path) ? drawerSelectedItem : null,
    color: location.pathname.includes(path) ? drawerSelectedColor  : 'inherit',
    width: '95%'
  });

  const listItems = [
    {
      path: INTERNALROUTES.DASHBOARD,
      title: "Dashboard",
      access: "Dashboard",
      icon: (path) => <DashboardIcon style={selectedStyle(path)} />,
      group: "",
    },
    {
      path: INTERNALROUTES.ALLINDENTS,
      title: "All Indents",
      access: "All Indents",
      icon: (path) => <FormatIndentIncreaseIcon style={selectedStyle(path)} />,
      group: "",
    },
    {
      path: INTERNALROUTES.ALLPURCHASES,
      title: "All Purchases",
      access: "All Purchases",
      icon: (path) => <ShoppingBagOutlinedIcon style={selectedStyle(path)} />,
      group: "",
    },
    {
      path: INTERNALROUTES.SITETRANSFERS,
      title: "Site Transfers",
      access: "Site Transfers",
      icon: (path) => <LeakAddOutlinedIcon style={selectedStyle(path)} />,
      group: "",
    },
    {
      path: INTERNALROUTES.SITES,
      title: "Sites",
      access: "Sites",
      icon: (path) => <ViewArrayOutlinedIcon style={selectedStyle(path)} />,
      group: "",
    },
    {
      path: INTERNALROUTES.VENDORS,
      title: "Vendors",
      access: "Vendors",
      icon: (path) => <StorefrontOutlinedIcon style={selectedStyle(path)} />,
      group: "",
    },
    {
      path: INTERNALROUTES.ITEMMASTERS,
      title: "Item Masters",
      access: "Item Masters",
      icon: (path) => <CategoryOutlinedIcon style={selectedStyle(path)} />,
      group: "",
    },
    {
      path: INTERNALROUTES.UNITMASTERS,
      title: "Unit Masters",
      access: "Unit Masters",
      icon: (path) => <AccountTreeOutlinedIcon style={selectedStyle(path)} />,
      group: "",
    },
    {
      path: INTERNALROUTES.COMPANYMASTERS,
      title: "Company Masters",
      access: "Company Masters",
      icon: (path) => <ApartmentOutlinedIcon style={selectedStyle(path)} />,
      group: "",
    },
    {
      path: INTERNALROUTES.USERPERMISSIONS,
      title: "User Permissions",
      access: "User Permissions",
      icon: (path) => <AssignmentIndOutlinedIcon style={selectedStyle(path)} />,
      group: "",
    },
  ];

  return (
    <div style={styles.drawerContentDiv}>
      <List disablePadding style={styles.root}>
        {listItems.map((list, index) => {
          if (
            // props?.user?.accessibleModules?.find((a) => a === list.access)
            1 > 0
          ) {
            return (
              <div key={`ListItemContainer-${index}`}>
                {/* {
                    ['Sales', 'Dispatch', 'Customers', 'Admin Configurations', 'Reports'].map(h => {
                      return (
                        list.access === groupedItemList[h]?.[0]?.access && (
                          props.open
                            ? <ListSubheader
                              style={styles.subHeaderStyle}
                              style={{ display: !props.open && 'none' }}
                            >
                              {h}
                            </ListSubheader>
                            : <Divider
                              style={{ margin: '10px 0', backgroundColor: '#eee' }}
                              variant="middle"
                            />
                        )
                      )
                    })
                  } */}
                <ListItemButton
                  title={list.title}
                  // components="button"
                  key={`ListItem-${index}`}
                  selected={selectedIndex === index}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedIndex(index)
                    navigate(list.path);
                  }}
                  style={{
                    borderRadius: 30,
                    backgroundColor: location.pathname.includes(list.path)
                      ? drawerSelectedItem
                      : null,
                      color: location.pathname.includes(list.path) ? drawerSelectedColor  : 'inherit',
                    paddingLeft: "10px",
                    width: '95%'
                  }}
                >
                  <ListItemIcon style={styles.iconColor}>
                    {list.icon(list.path)}
                  </ListItemIcon>
                  <ListItemText
                    style={styles.fontStyle}
                    primary={list.title}
                    primaryTypographyProps={{
                      style: { fontSize: "0.99em" },
                    }}
                    // style={{ display: !props.open && 'none' }}
                  />
                </ListItemButton>
              </div>
            );
          } else return null;
        })}
      </List>
    </div>
  );
};

export default DrawerContents;
