import { Button, Divider, Grid, Typography } from "@mui/material";
import Layout from "../../../layouts/internalLayout";
import Bread from "../../comman/Bread";
import ContentPaper from "../../comman/ContentPaper";
import moment from "moment";
import { handleYearValueShown } from "../../../method";
import { drawerSelectedColor } from "../../../constant/colors";
import INTERNALROUTES from "../../../constant/internalRoutes";

const styles = {
  detailBox: {
    width: "100%",
    height: "100%",
    paddingBottom: "5%",
  },
  detailContainer: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  title: {
    fontSize: 18,
    color: drawerSelectedColor,
    fontWeight: 500,
  },
};

const items = [
  {
    itemName: "TMT Bar 10MM",
    quantity: "20",
    unit: "Bundle, metric , Piece,",
    remark:
      "checking dummy remark text for particular item as very long text to test",
  },
  {
    itemName: "TMT Bar (F) FE 500D",
    quantity: "12.5",
    unit: "metric",
    remark: "checking dummy remark text for particular item",
  },
  {
    itemName: "TMT Bar (F) FE 500D",
    quantity: "12.5",
    unit: "metric",
    remark: "checking dummy remark text for particular item",
  },
  {
    itemName: "TMT Bar (F) FE 500D",
    quantity: "12.5",
    unit: "metric",
    remark: "checking dummy remark text for particular item",
  },
  {
    itemName: "TMT Bar (F) FE 500D",
    quantity: "12.5",
    unit: "metric",
    remark: "checking dummy remark text for particular item",
  },
];

const RowDetailView = ({ title, value, fontSize = "" }) => {
  return (
    <>
      <div style={styles.detailContainer}>
        {title !== "" ? (
          <>
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: fontSize ? fontSize : 14,
              }}
            >
              {title}
            </Typography>
            <Typography
              style={{
                textAlign: "right",
                width: "55%",
                fontSize: fontSize ? fontSize : 14,
              }}
            >
              {value}
            </Typography>
          </>
        ) : (
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: fontSize ? fontSize : "inherit",
            }}
          >
            {value}
          </Typography>
        )}
      </div>
      {/* <Divider /> */}
    </>
  );
};

const ItemCard = ({ itemName, quantity, unit, remark }) => {
  return (
    <Grid item xs={6} md={4} lg={3}>
      <div
        style={{
          border: "1px solid #aaa",
          borderRadius: 16,
          // width: 300,
          height: "100%",
          padding: "0 10px",
          marginTop: 10,
        }}
      >
        <div style={styles.detailContainer}>
          <Typography
            style={{
              textAlign: "left",
              width: "55%",
              fontSize: 14,
            }}
          >
            {itemName}
          </Typography>
          <Typography
            style={{
              textAlign: "right",
              width: "43%",
              fontSize: 14,
            }}
          >
            {`${quantity} ${unit}`}
          </Typography>
        </div>
        <Divider />
        <div style={styles.detailContainer}>
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 12,
            }}
          >
            {remark}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

const DisplayAllIndents = (props) => {
  const { navigate, location } = props;

  return (
    <Layout>
      <ContentPaper
        bread={
          <div>
            <Bread
              data={[
                {
                  title: "All Indents",
                  onClick: () => navigate(-1),
                },
                {
                  title: `${" 24-25/Comp1/0001"}`,
                },
              ]}
            />
          </div>
        }
      >
        <div style={styles.detailBox}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                // border: "1px solid #ddd",
                borderRadius: 16,
                width: 350,
                padding: 10,
              }}
            >
              <Typography style={styles.title}> Basic Detail </Typography>
              <Divider />
              <RowDetailView
                title={"Indent Date"}
                value={moment(new Date()).format("DD MMM YYYY")}
              />
              <RowDetailView
                title={"FY"}
                value={`${handleYearValueShown(0)}-${handleYearValueShown(1)}`}
              />
              <RowDetailView
                title={"Received Date"}
                value={moment(new Date("2024-08-10")).format("DD MMM YYYY")}
              />
              <RowDetailView
                title={"Audit"}
                value={""}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              justifyContent: "space-between",
              flexWrap: "wrap",
              margin: "20px 0",
            }}
          >
            <div
              style={{
                // border: "1px solid #ddd",
                borderRadius: 16,
                width: 340,
                padding: 10,
              }}
            >
              <Typography style={styles.title}> Sites Detail </Typography>
              <Divider />
              <RowDetailView title={"Name"} value={"Site JKLMN"} />
              <RowDetailView title={"Phone"} value={"+91 8967873498"} />
              <RowDetailView
                title={"Address"}
                value={"D 456 sector-32 Ghaziabad UP"}
              />
            </div>
            <div
              style={{
                // border: "1px solid #ddd",
                borderRadius: 16,
                width: 340,
                padding: 10,
              }}
            >
              <Typography style={styles.title}> Vendors Detail </Typography>
              <Divider />
              <RowDetailView title={"Name"} value={"Vendor XYZWQ"} />
              <RowDetailView title={"Phone"} value={"+91 9812345678"} />
              <RowDetailView
                title={"Address"}
                value={"A 123 sector-45 Gurgaon HR"}
              />
              <RowDetailView title={"GSTIN"} value={"27AAAAP0267H2ZN"} />
            </div>
          </div>
          <div
            style={{
              // border: "1px solid #ddd",
              borderRadius: 16,
              // width: "70%",
              padding: 10,
              marginTop: 20,
            }}
          >
            <Typography style={styles.title}> Item Detail </Typography>
            {/* <Divider /> */}
            {/* <Grid container spacing={2} wrap="wrap">
              <ItemCard
                itemName={"TMT Bar 10MM"}
                quantity={"20 "}
                unit={"Bundle"}
                remark={
                  "checking dummy remark text for particular item as very long text to test"
                }
              />
              <ItemCard
                itemName={"TMT Bar (F) FE 500D"}
                quantity={"12.5 "}
                unit={"metric"}
                remark={"checking dummy remark text for particular item "}
              />
              <ItemCard
                itemName={"TMT Bar (F) FE 500D"}
                quantity={"12.5 "}
                unit={"metric"}
                remark={"checking dummy remark text for particular item "}
              />
             </Grid> */}
            <div
              style={{
                margin: "20px 0",
                border: "1px solid #ddd",
                borderRadius: 16,
                padding: 8,
              }}
            >
              <Grid
                container
                spacing={0}
                wrap="wrap"
                style={{ margin: "8px 0" }}
              >
                <Grid item lg={2} style={{ fontSize: 15 }}>
                  Item Name
                </Grid>
                <Grid item lg={1} style={{ fontSize: 15, textAlign: "center" }}>
                  Quantity
                </Grid>
                <Grid item lg={3} style={{ fontSize: 15, textAlign: "center" }}>
                  Units
                </Grid>
                <Grid item lg={6} style={{ fontSize: 15, textAlign: "end" }}>
                  Remark
                </Grid>
              </Grid>
              <Divider />
              {items.map((d,i) => {
                return (
                  <>
                    <Grid
                      container
                      spacing={0}
                      wrap="wrap"
                      style={{ margin: "8px 0" }}
                      key={d.itemName +"1000"}
                    >
                      <Grid item lg={2} style={{ fontSize: 13 }}>
                        {d.itemName}
                      </Grid>
                      <Grid
                        item
                        lg={1}
                        style={{ fontSize: 13, textAlign: "center" }}
                      >
                        {d.quantity}
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        style={{ fontSize: 13, textAlign: "center" }}
                      >
                        {d.unit}
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        style={{ fontSize: 13, textAlign: "end" }}
                      >
                        {d.remark}
                      </Grid>
                    </Grid>
                    {i === items.length - 1 ? null : <Divider /> }
                  </>
                );
              })}
            </div>
          </div>
          <div style={{ margin: "30px 0" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: 90 }}
              onClick={() => navigate(`${INTERNALROUTES.ALLINDENTS}/edit/${'123456'}`)}
            >
              Edit
            </Button>
            <Button
              variant="text"
              style={{ marginLeft: 30, color: "#333" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ContentPaper>
    </Layout>
  );
};

export default DisplayAllIndents;
