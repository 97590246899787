import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SitesIndex from "../../components/internal/Sites";
import DisplaySites from "../../components/internal/Sites/DisplaySites";
import ManageSites from "../../components/internal/Sites/ManageSites";
import axios from "axios";
import APIURL from "../../constant/ApiRoutes";

const SitesContainer = (props) => {
  const navigate = useNavigate(); // navigate for navigation
  const location = useLocation();

  const getAllSites = () => {
    return axios.get(APIURL.GET_SITES);
  };

  const saveSite = (data) => {
    return axios.post(APIURL.GET_SITES, data);
  };

  const getSingleSite = (id) => {
    return axios.get(`${APIURL.GET_SITES}${id}/`);
  };

  const updateSingleSite = (id, data) => {
    return axios.patch(`${APIURL.GET_SITES}${id}/`, data);
  };

  return (
    <Routes>
      <Route
        path="view/:siteId"
        element={
          <DisplaySites
            {...props}
            navigate={navigate}
            location={location}
            getSingleSite={getSingleSite}
          />
        }
      />
      <Route
        path="edit/:siteId"
        element={
          <ManageSites
            {...props}
            navigate={navigate}
            location={location}
            getSingleSite={getSingleSite}
            updateSingleSite={updateSingleSite}
          />
        }
      />
      <Route
        path="add"
        element={
          <ManageSites
            {...props}
            navigate={navigate}
            location={location}
            saveSite={saveSite}
          />
        }
      />
      <Route
        path="/"
        element={
          <SitesIndex
            {...props}
            navigate={navigate}
            location={location}
            getAllSites={getAllSites}
          />
        }
      />
    </Routes>
  );
};

export default SitesContainer;
