import React from "react";
import {
  poppinsBold,
  poppinsLight,
  poppinsMedium,
  poppinsRegular,
} from "../fonts/fonts";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          poppinsBold,
          poppinsLight,
          poppinsMedium,
          poppinsRegular,
        ],
      },
    },
  },
});

function withMUI(Component) {
  function WithMUI(props) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return WithMUI;
}

export default withMUI;
