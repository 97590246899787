import { Button, TextField, Typography } from "@mui/material";
import Layout from "../../../layouts/internalLayout";
import Bread from "../../comman/Bread";
import ContentPaper from "../../comman/ContentPaper";
import { useEffect, useState } from "react";

const ManageUnitMasters = (props) => {
  const { navigate, location } = props;

  const [unitName, setUnitName] = useState("");
  const [rows, setRows] = useState({});

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      props
        .getSingleUnit(Number(location.pathname.split("/")[3]))
        .then((res) => {
          if (res.status === 200) {
            setRows(res.data);
            setUnitName(res.data.unit_name);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleSubmit = (type) => {
    let data = { unit_name: unitName };
    if (type === "add") {
      props
        .saveUnit(data)
        .then((res) => {
          if (res.status === 201) {
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    } else {
      props
        .updateSingleUnit(rows.id, data)
        .then((res) => {
          if (res.status === 200) {
            navigate(-2);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Layout>
      <ContentPaper
        bread={
          <div>
            <Bread
              data={[
                {
                  title: "Unit Masters",
                  onClick: () => navigate(-1),
                },
                {
                  title: `${
                    location.pathname.includes("edit") ? "Edit" : "Add"
                  }`,
                },
              ]}
            />
          </div>
        }
      >
        <div style={{ height: "55vh" }}>
          <div
            style={{
              display: "flex",
              width: "40%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              Unit Name
            </Typography>
            <TextField
              size="small"
              value={unitName || ""}
              onChange={(e) => setUnitName(e.target.value)}
              style={{ width: 280 }}
            />
          </div>
          <div style={{ margin: "30px 0" }}>
            {location.pathname.includes("edit") ? (
              <Button
                variant="contained"
                color="primary"
                disabled={!unitName}
                onClick={() => handleSubmit("update")}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={!unitName}
                onClick={() => handleSubmit("add")}
              >
                Submit
              </Button>
            )}
            <Button
              variant="text"
              style={{ marginLeft: 30, color: "#333" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ContentPaper>
    </Layout>
  );
};

export default ManageUnitMasters;
