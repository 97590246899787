module.exports = {
  poppinsLight: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 300,
    src: `local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2) format('woff2')`,
    unicodeRange:
      "U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB",
  },
  poppinsRegular: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2')`,
    unicodeRange:
      "U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB",
  },
  poppinsMedium: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 500,
    src: `local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format('woff2')`,
    unicodeRange:
      "U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB",
  },
  poppinsBold: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 700,
    src: `local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format('woff2')`,
    unicodeRange:
      "U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB",
  },
};

/*
100 Extra Light or Ultra Light
200 Light or Thin
300 Book or Demi
400 Normal or Regular
500 Medium
600 Semibold, Demibold
700 Bold
800 Black, Extra Bold or Heavy
900 Extra Black, Fat, Poster or Ultra Black
*/
