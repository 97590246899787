import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import CompanyMastersIndex from "../../components/internal/CompanyMasters";
import DisplayCompanyMasters from "../../components/internal/CompanyMasters/DisplayCompanyMasters";
import ManageCompanyMasters from "../../components/internal/CompanyMasters/ManageCompanyMasters";
import axios from "axios";
import APIURL from "../../constant/ApiRoutes";

const CompanyMastersContainer = (props) => {
  const navigate = useNavigate(); // navigate for navigation
  const location = useLocation();

  const getAllCompanyMasters = () => {
    return axios.get(APIURL.GET_COMPANIES);
  };

  const saveCompanyMaster = (data) => {
    return axios.post(APIURL.GET_COMPANIES, data);
  };

  const getSingleCompanyMaster = (id) => {
    return axios.get(`${APIURL.GET_COMPANIES}${id}/`);
  };

  const updateSingleCompanyMaster = (id, data) => {
    return axios.patch(`${APIURL.GET_COMPANIES}${id}/`, data);
  };

  return (
    <Routes>
      <Route
        path="view/:companyMasterId"
        element={
          <DisplayCompanyMasters
            {...props}
            navigate={navigate}
            location={location}
            getSingleCompanyMaster={getSingleCompanyMaster}
          />
        }
      />
      <Route
        path="edit/:companyMasterId"
        element={
          <ManageCompanyMasters
            {...props}
            navigate={navigate}
            location={location}
            getSingleCompanyMaster={getSingleCompanyMaster}
            updateSingleCompanyMaster={updateSingleCompanyMaster}
          />
        }
      />
      <Route
        path="add"
        element={
          <ManageCompanyMasters
            {...props}
            navigate={navigate}
            location={location}
            saveCompanyMaster={saveCompanyMaster}
          />
        }
      />
      <Route
        path="/"
        element={
          <CompanyMastersIndex
            {...props}
            navigate={navigate}
            location={location}
            getAllCompanyMasters={getAllCompanyMasters}
          />
        }
      />
    </Routes>
  );
};

export default CompanyMastersContainer;
