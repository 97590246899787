import Layout from "../../../layouts/internalLayout";
import Heading from "../../comman/Heading";

const DashBoardIndex = (props) => {
  return (
    <Layout>
      <div>
        <Heading pageTitle={"Dashboard"} />
      </div>
    </Layout>
  );
};

export default DashBoardIndex;
