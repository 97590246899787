import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { ContentBody, drawerBackground } from "../../constant/colors";
import { checkEmailAddress } from "../../method";
import INTERNALROUTES from "../../constant/internalRoutes";

const styles = {
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    backgroundColor: ContentBody,
  },
  topContainer: {
    minHeight: 500,
    border: "1px solid #aaa",
    borderRadius: 16,
    padding: "15px 20px",
    backgroundColor: drawerBackground,
  },
};

const LoginPage = (props) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [radioValue, setRadioValue] = useState("password");
  // console.log(props);

  const handleClearState = () => {
    setEmail("");
    setPassword("");
    setOtp("");
    setIsOtpSent(false);
  };

  const handleLogin = () => {
    if (radioValue === "password")
      props.loginWithUsernamePassword(email, password);
  };

  const handleSendOtp = () => {
    setIsOtpSent(true);
    console.log("OTP sent to", email);
  };

  const handleRegister = () => {
    props
      .handleRegisteration(email, password)
      .then((res) => {
        console.log("r", res);
        setIsRegistering(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (
        isRegistering &&
        !(!email || !password || (email && !checkEmailAddress(email)))
      ) {
        handleRegister();
      } else if (
        !isRegistering &&
        !(
          !email ||
          (email && !checkEmailAddress(email)) ||
          (radioValue === "otp" && otp.length !== 6) ||
          (radioValue === "password" && !password)
        )
      ) {
        handleLogin();
      }
    }
  };

  return (
    <div style={styles.box}>
      <Container maxWidth="xs" style={styles.topContainer}>
        <Typography variant="h4" align="center" gutterBottom>
          {isRegistering ? "Register" : "Login"}
        </Typography>
        <Box
          mb={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isRegistering && (
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="controlled-radio-buttons-group"
              value={radioValue}
              onChange={(e) => setRadioValue(e.target.value)}
              style={{ margin: "10px 0" }}
            >
              <FormControlLabel
                value="password"
                control={<Radio />}
                label="Password"
              />
              <FormControlLabel value="otp" control={<Radio />} label="OTP" />
            </RadioGroup>
          )}
          <TextField
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            margin="normal"
            style={{ width: "80%" }}
            error={email && !checkEmailAddress(email)}
            helperText={
              email && !checkEmailAddress(email) ? "Invalid email" : ""
            }
          />
          {(radioValue === "password" || isRegistering) && (
            <TextField
              style={{ width: "80%" }}
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              onKeyDown={handleKeyDown}
            />
          )}
          {!isRegistering && radioValue === "otp" && (
            <TextField
              style={{ width: "80%" }}
              label="OTP"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              onKeyPress={(event) => {
                if (event.charCode >= 48 && event.charCode <= 57) {
                  // let it happen, don't do anything
                } else {
                  event.preventDefault();
                }
              }}
              margin="normal"
              error={otp && otp.length !== 6}
              helperText={otp && otp.length !== 6 ? "Invalid OTP" : ""}
            />
          )}
          {!isRegistering && radioValue === "otp" ? (
            <div style={{ width: "80%", textAlign: "end" }}>
              <Button
                style={{ width: "30%", margin: "10px 0" }}
                variant="text"
                color="primary"
                onClick={handleSendOtp}
                disabled={!email}
              >
                Send OTP
              </Button>
            </div>
          ) : null}
          {isRegistering ? (
            <Button
              style={{ width: "80%", marginTop: 20 }}
              variant="contained"
              color="primary"
              onClick={handleRegister}
              disabled={
                !email || !password || (email && !checkEmailAddress(email))
              }
            >
              Register
            </Button>
          ) : (
            <Button
              style={{ width: "80%", marginTop: 10 }}
              variant="contained"
              color="primary"
              onClick={handleLogin}
              disabled={
                !email ||
                (email && !checkEmailAddress(email)) ||
                (radioValue === "otp" && otp.length !== 6) ||
                (radioValue === "password" && !password)
              }
            >
              Login
            </Button>
          )}
        </Box>
        <Box
          textAlign="center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography style={{ color: "#999", fontSize: 12 }}>
            {!isRegistering && `Don't have an account?`}
          </Typography>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setIsRegistering(!isRegistering);
              handleClearState();
            }}
            style={{ fontSize: 13, textTransform: "none" }}
          >
            {isRegistering ? "Back to Login" : "Register"}
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default LoginPage;
