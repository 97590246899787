import Layout from "../../../layouts/internalLayout";
import Heading from "../../comman/Heading";

const SiteTransfersIndex = (props) => {
  return (
    <Layout>
      <div>
        <Heading pageTitle={"Site Transfers"} />
      </div>
    </Layout>
  );
};

export default SiteTransfersIndex;
