import { Button } from "@mui/material";
import Layout from "../../../layouts/internalLayout";
import ContentPaper from "../../comman/ContentPaper";
import Heading from "../../comman/Heading";
import { drawerSelectedColor, whiteColor } from "../../../constant/colors";
import CommanTable from "../../comman/CommanTable";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";

const styles = {
  addButton: {
    display: "flex",
    flex: 2,
    placeContent: "flex-end",
  },
  primaryBackground: {
    backgroundColor: drawerSelectedColor,
    color: whiteColor,
    fontSize: "0.9em",
  },
};

const TableHeader = [
  { label: "Name", key: "name" },
  { label: "Phone", key: "contact_details" },
  { label: "Address", key: "address" },
  { label: "Last Edited", key: "modified_at" },
];

const SitesIndex = (props) => {
  const {navigate, location } = props;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (rows.length <= 0) {
      props
        .getAllSites()
        .then((res) => {
          if (res.status === 200) {
            setRows(res.data.results);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <Layout>
      <ContentPaper heading={<Heading pageTitle={"Sites"} />}>
        <div style={styles.addButton}>
          <Button
            style={styles.primaryBackground}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("add", { relative: "path" })}
          >
            ADD NEW
          </Button>
        </div>
        <CommanTable TableHeader={TableHeader} rows={rows} />
      </ContentPaper>
    </Layout>
  );
};

export default SitesIndex;
