import { Paper } from "@mui/material";

const styles = {
  mainDiv: {
    width: "100%",
    // height: "100%",
    paddingRight: "25px",
  },
  displayData: {
    marginTop: 10,
    width: "100%",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    padding: "20px",
    paddingBottom: 0,
    marginTop: "2px 20px 0px 20px",
  },
  filterSection: {
    // display: "flex",
  },
};

const ContentPaper = (props) => {
  const { heading, bread } = props
  return (
    <div style={styles.mainDiv}>
    {heading ?? bread }
    <div style={styles.displayData}>
      <Paper style={styles.paperStyle}>
        <div style={styles.filterSection}>{props.children}</div>
      </Paper>
    </div>
    </div>
  );
};

export default ContentPaper;
