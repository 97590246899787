import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UnitMastersIndex from "../../components/internal/UnitMasters";
import DisplayUnitMasters from "../../components/internal/UnitMasters/DisplayUnitMasters";
import ManageUnitMasters from "../../components/internal/UnitMasters/ManageUnitMasters";
import axios from "axios";
import APIURL from "../../constant/ApiRoutes";

const UnitMastersContainer = (props) => {
  const navigate = useNavigate(); // navigate for navigation
  const location = useLocation();

  const getAllUnits = () => {
    return axios.get(APIURL.GET_UNITS);
  };
  
  const saveUnit = (data) => {
    return axios.post(APIURL.GET_UNITS, data);
  };

  const getSingleUnit = (id) => {
    console.log(id)
    return axios.get(`${APIURL.GET_UNITS}${id}/`);
  };

  const updateSingleUnit = (id, data) => {
    return axios.patch(`${APIURL.GET_UNITS}${id}/`, data);
  };

  return (
    <Routes>
      <Route
        path="view/:unitId"
        element={
          <DisplayUnitMasters
            {...props}
            navigate={navigate}
            location={location}
            getSingleUnit={getSingleUnit}
          />
        }
      />
      <Route
        path="edit/:unitId"
        element={
          <ManageUnitMasters
            {...props}
            navigate={navigate}
            location={location}
            getSingleUnit={getSingleUnit}
            getAllUnits={getAllUnits}
            updateSingleUnit={updateSingleUnit}
          />
        }
      />
      <Route
        path="add"
        element={
          <ManageUnitMasters
            {...props}
            navigate={navigate}
            location={location}
            saveUnit={saveUnit}
          />
        }
      />
      <Route
        path="/"
        element={
          <UnitMastersIndex
            {...props}
            navigate={navigate}
            location={location}
            getAllUnits={getAllUnits}
          />
        }
      />
    </Routes>
  );
};

export default UnitMastersContainer;
