import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { drawerBackground } from "../../constant/colors";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";

const CommanTable = ({ TableHeader, rows }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <TableContainer
        component={Paper}
        style={{ height: "calc(100vh - 220px)" }}
      >
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
          <TableHead style={{ backgroundColor: drawerBackground }}>
            <TableRow>
              {TableHeader.map((column,i) => (
                <TableCell width={`${100 / TableHeader}%`} key={column.key} align={i === TableHeader.length - 1 ? "right" : i !== 0 ? "center" : "left" }>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={
                      {
                        cursor: 'pointer'
                      }
                    }
                  >
                    {TableHeader.map((column,i) => (
                      <TableCell
                        key={column.key}
                        align={i === TableHeader.length - 1 ? "right" : i !== 0 ? "center" : "left" }
                        onClick={(e) => {
                          if (column.key === "actions") {
                            e.preventDefault();
                            e.stopPropagation();
                          } else navigate(`view/${row?.id}`, { relative: "path" });
                        }}
                      >
                        {['modified_at'].includes(column.key) ? moment(row[column.key]).format('DD MMM YYYY') : row[column.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default CommanTable;
