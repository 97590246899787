import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import Layout from "../../../layouts/internalLayout";
import Heading from "../../comman/Heading";
import {
  drawerBackground,
  drawerSelectedColor,
  whiteColor,
} from "../../../constant/colors";

import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentPaper from "../../comman/ContentPaper";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const styles = {
  mainDiv: {
    width: "100%",
    // height: "100%",
    paddingRight: "25px",
  },
  displayData: {
    marginTop: 10,
    width: "100%",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    padding: "20px",
    paddingBottom: 0,
    marginTop: "20px 20px 0px 20px",
  },
  filterSection: {
    display: "flex",
    alignItems: "flex-start",
    flex: "1",
  },
  addButton: {
    display: "flex",
    flex: 2,
    placeContent: "flex-end",
  },
  primaryBackground: {
    backgroundColor: drawerSelectedColor,
    color: whiteColor,
    fontSize: "0.9em",
  },
};

const TableHeader = [
  { label: "Indent No.", key: "indent_number" },
  { label: "Date", key: "date" },
  { label: "Site", key: "site_location" },
  { label: "Vendor", key: "vendor" },
  { label: "Items", key: "item_details" },
  { label: "Required Date", key: "required_date" },
  { label: "Status", key: "status" },
  { label: "Comments", key: "comments" },
  { label: "Actions", key: "actions" },
];

const rows = [
  {
    indent_number: "24-25/Comp1/0001",
    date: "07-Jun-2024",
    financial_year: "2024-2025",
    vendor: "XYZ Supplies",
    site_location: "Site 1",
    item_details: "Item 1, Item 2",
    required_date: "20-Aug-2024",
    status: "Draft",
    approved_by: "John Doe",
    comments: "Urgent",
    audit_trail: "07-Jun-2024",
    actions: "",
  },
  // Add more rows as needed
];

const sites = ["Site A", "Site B"];
const vendors = ["Vendor X", "Vendor Y"];
const status = [
  "Draft",
  "Approved",
  "Rejected",
  "Partially Fulfilled",
  "Fulfilled",
  "Closed",
  "Cancelled",
];

const AllIndentsIndex = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Layout>
      <ContentPaper heading={<Heading pageTitle={"All Indents"} />}>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", flexWrap: "wrap", width: "80%" }}>
            <Grid container spacing={2}>
              <Grid item xs={8} md={3}>
                <TextField
                  size="small"
                  label="Search"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ transform: 'scale(0.8)' }}>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8} md={3}>
                <Autocomplete
                  size="small"
                  options={sites}
                  // value={formData.selectedSite}
                  // onChange={handleChange("selectedSite")}
                  renderInput={(params) => (
                    <TextField {...params} label="Site" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={8} md={3}>
                <Autocomplete
                  size="small"
                  options={vendors}
                  // value={formData.selectedVendor}
                  // onChange={handleChange("selectedVendor")}
                  renderInput={(params) => (
                    <TextField {...params} label="Vendor" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={8} md={3}>
                <Autocomplete
                  size="small"
                  options={status}
                  // value={formData.selectedVendor}
                  // onChange={handleChange("selectedVendor")}
                  renderInput={(params) => (
                    <TextField {...params} label="Status" fullWidth />
                  )}
                />
              </Grid>
            </Grid>
          </div>
          <div style={styles.addButton}>
            <Button
              style={styles.primaryBackground}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("add", { relative: "path" })}
            >
              ADD NEW
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <TableContainer
            component={Paper}
            style={{ height: "calc(100vh - 220px)" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead style={{ backgroundColor: drawerBackground }}>
                <TableRow>
                  {TableHeader.map((column) => (
                    <TableCell key={column.key} align="left">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={
                          {
                            // "&:last-child td, &:last-child th": { border: 0 },
                          }
                        }
                      >
                        {TableHeader.map((column) => (
                          <TableCell
                            key={column.key}
                            align="left"
                            onClick={(e) => {
                              if (column.key === "actions") {
                                e.preventDefault();
                                e.stopPropagation();
                              } else
                                navigate(`view/${1234}`, { relative: "path" });
                            }}
                          >
                            {row[column.key]}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </ContentPaper>
    </Layout>
  );
};

export default AllIndentsIndex;
