import { useEffect, useState } from "react";
import Layout from "../../../layouts/internalLayout";
import Bread from "../../comman/Bread";
import ContentPaper from "../../comman/ContentPaper";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";

const styles = {
  innerRowContainer: {
    display: "flex",
    width: "40%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  leftText: {
    textAlign: "left",
    width: "40%",
    fontSize: 16,
  },
};

const ManageItemMasters = (props) => {
  const { navigate, location } = props;

  console.log(location);

  const [itemData, setItemData] = useState({
    name: "",
    unit_name: null,
    category: "",
    specification: "",
  });

  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      props
        .getSingleItem(Number(location.pathname.split("/")[3]))
        .then((res) => {
          if (res.status === 200) {
            let data = res.data;
            props
              .getAllUnits()
              .then((res) => {
                if (res.status === 200) {
                  let unitData = res.data.results;
                  const foundItem = unitData.find((d) => d.id === data.uom);
                  delete data.uom;
                  data.unit_name = foundItem;
                  setItemData(data);
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (unitList.length <= 0) {
      props
        .getAllUnits()
        .then((res) => {
          if (res.status === 200) {
            setUnitList(res.data.results);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleChange = (field, value) => {
    setItemData((prevValue) => {
      return {
        ...prevValue,
        [field]: value,
      };
    });
  };

  const handleSubmit = (type) => {
    let data = {
      name: itemData.name,
      uom: itemData.unit_name.id,
      category: itemData.category,
      specification: itemData.specification,
    };
    if (type === "add") {
      props
        .saveItem(data)
        .then((res) => {
          if (res.status === 201) {
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    } else {
      props
        .updateSingleItem(itemData.id, data)
        .then((res) => {
          if (res.status === 200) {
            navigate(-2);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Layout>
      <ContentPaper
        bread={
          <div>
            <Bread
              data={[
                {
                  title: "Item Masters",
                  onClick: () => navigate(-1),
                },
                {
                  title: `${
                    location.pathname.includes("edit") ? "Edit" : "Add"
                  }`,
                },
              ]}
            />
          </div>
        }
      >
        <div style={{ height: "75vh" }}>
          <div style={styles.innerRowContainer}>
            <Typography style={styles.leftText}>Item Name</Typography>
            <TextField
              size="small"
              value={itemData.name || ""}
              onChange={(e) => handleChange("name", e.target.value)}
              style={{ width: 280 }}
            />
          </div>
          <div style={styles.innerRowContainer}>
            <Typography style={styles.leftText}>Unit</Typography>
            {console.log(unitList)}
            <Autocomplete
              // multiple
              size="small"
              // limitTags={2}
              options={unitList}
              getOptionLabel={(option) => option.unit_name || ""}
              value={itemData.unit_name}
              style={{ width: 280 }}
              onChange={(e, newValue) => handleChange("unit_name", newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </div>
          <div style={styles.innerRowContainer}>
            <Typography style={styles.leftText}>Category</Typography>
            <TextField
              size="small"
              value={itemData.category || ""}
              onChange={(e) => handleChange("category", e.target.value)}
              style={{ width: 280 }}
            />
          </div>
          <div style={styles.innerRowContainer}>
            <Typography style={styles.leftText}>Specifications</Typography>
            <TextField
              multiline
              minRows={2}
              maxRows={3}
              size="small"
              value={itemData.specification || ""}
              onChange={(e) => handleChange("specification", e.target.value)}
              style={{ width: 280 }}
            />
          </div>
          <div style={{ margin: "30px 0" }}>
            {location.pathname.includes("edit") ? (
              <Button
                variant="contained"
                color="primary"
                disabled={
                !itemData.name ||
                !itemData.unit_name ||
                !itemData.category ||
                !itemData.specification
              }
                onClick={() => handleSubmit("update")}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !itemData.name ||
                  !itemData.unit_name ||
                  !itemData.category ||
                  !itemData.specification
                }
                onClick={() => handleSubmit("add")}
              >
                Submit
              </Button>
            )}
            <Button
              variant="text"
              style={{ marginLeft: 30, color: "#333" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ContentPaper>
    </Layout>
  );
};

export default ManageItemMasters;
