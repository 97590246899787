import axios from "axios";
import LoginPage from "../../components/comman/Login";
import APIURL from "../../constant/ApiRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import INTERNALROUTES from "../../constant/internalRoutes";

const LoginIndex = (props) => {
  const navigate = useNavigate(); // navigate for navigation
  const location = useLocation();

  useEffect(() => {
    if(props?.user && props?.user?.success){
      navigate(INTERNALROUTES.DASHBOARD)
    }
  },[props.user])


  const handleRegisteration = (email, password) => {
    let data = { email: email, password: password };
    return axios.post(APIURL.REGISTER, data);
  };

  return (
    <LoginPage
      {...props}
      navigate={navigate}
      location={location}
      handleRegisteration={handleRegisteration}
    />
  );
};

export default LoginIndex;
