import Layout from "../../../layouts/internalLayout";
import Heading from "../../comman/Heading";

const UserPermissionsIndex = (props) => {
  return (
    <Layout>
      <div>
        <Heading pageTitle={"User Permissions"} />
      </div>
    </Layout>
  );
};

export default UserPermissionsIndex;
