import { createContext, useEffect, useState } from "react"
import APIURL from "../constant/ApiRoutes";
import axios from "axios";

const log = (mode, message) => console[mode]("AUTOLOGIN: ", message);
export const AuthUserContext = createContext(null);

const withAuthentication = (WrappedComponent) => {
  const WithAuthentication = (props) => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
      let user = localStorage.getItem('user');
      user = JSON.parse(user)
      if (user) {
        setAuthUser(user)
      }
    }, []);

    useEffect(() => {
      const initAuthUser = () => {
        let user = localStorage.getItem('user');
        if (user) {
          user = JSON.parse(user);
          const token = user.token || false;
          if (token) {
            const validatedUser = userTokenValidator(token);
            if (validatedUser) {
              if (!user.success) {
                setAuthUser(user);
              } else {
                let last_action_at = localStorage.getItem('last_action_at');
                if (last_action_at) {
                  last_action_at = new Date(last_action_at);
                  const allow_time = new Date(new Date().getTime() - 30 * 60000);
                  if (last_action_at > allow_time) {
                    setAuthUser(user);
                  } else {
                    clearUser("Session was expired!");
                  }
                } else {
                  clearUser("Last activity unavailable/changed!");
                }
              }
            } else {
              clearUser("Invalid token/changed!");
            }
          } else {
            clearUser("Token unavailable!");
          }
        } else {
          log("error", "User not found!");
        }
      };
  
      const clearUser = (message) => {
        localStorage.removeItem('user');
        setAuthUser(null);
        log("error", message);
      };
  
      initAuthUser();
  
      const onStorageChange = () => {
        if (!localStorage.user) logout();
      };
  
      window.addEventListener('storage', onStorageChange);
      document.addEventListener('onAuthStateChanged', onAuthStateChanged, false);
  
      return () => {
        window.removeEventListener('storage', onStorageChange);
        document.removeEventListener('onAuthStateChanged', onAuthStateChanged, false);
      };
    }, []);

    const userTokenValidator = (token) => {
      if (token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const user = JSON.parse(window.atob(base64));
        if (user) {
          const exp = new Date(user.exp * 1000);
          if (exp < new Date()) {
            window.localStorage.removeItem("user");
            return null;
          } else {
            return {
              ...user,
              token,
            };
          }
        } else return null;
      } else return null;
    };

    const eventDispatcher = (data = null) => {
      const authEvent = new Event("onAuthStateChanged");
      if (data?.token) {
        authEvent.user = {
          ...userTokenValidator(data?.token),
          ...data,
        };
      } else {
        authEvent.user = userTokenValidator(false);
      }
      document.dispatchEvent(authEvent);
    };

    const onAuthStateChanged = ({ user }) => {
      if(user){
        localStorage.setItem("last_action_at", new Date().toISOString());
      }
      setAuthUser(user ? user : null);
    };


    const loginWithUsernamePassword = (email, password) => {
      let userData = {
        success: false,
        token: '',
        refresh_token: ''
      }
      new Promise((resolve, reject) => {
        let data = { email: email, password: password, verification_type: "password", };
        axios
          .post(APIURL.LOGIN, data)
          .then((res) => {
            // console.log(res);
            userData = {
              success: true,
              token: res.data.access_token,
              refresh_token: res.data.refresh_token
            }
            localStorage.setItem('user', JSON.stringify(userData))
            setAuthUser(userData)
            eventDispatcher(userData)
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    };
    const loginWithUsernameOtp = (email, password) => {
      new Promise((resolve, reject) => {
        let data = { email: email, password: password, verification_type: "otp", };
        axios
          .post(APIURL.LOGIN, data)
          .then((res) => {
            // console.log(res);
            setAuthUser(res)
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    };


    const getMobileOTP = (phoneNumber) => {
      // Your OTP logic here
    };

    const logout = () => {
      // Your logout logic here
      setAuthUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem("last_action_at");
      eventDispatcher();
    };

    return (
      <AuthUserContext.Provider value={authUser}>
        <WrappedComponent
          {...props}
          loginWithUsernamePassword={loginWithUsernamePassword}
          // loginWithPhoneNumber={loginWithPhoneNumber}
          getMobileOTP={getMobileOTP}
          logout={logout}
          // refreshToken={refreshToken}
          // getMobileUseOtp={getMobileUseOtp}
          // verifyMobileUseOtp={verifyMobileUseOtp}
        />
      </AuthUserContext.Provider>
    );
  };

  return WithAuthentication;
};

export default withAuthentication;