import { Button, Typography } from "@mui/material";
import Layout from "../../../layouts/internalLayout"
import Bread from "../../comman/Bread"
import ContentPaper from "../../comman/ContentPaper"
import INTERNALROUTES from "../../../constant/internalRoutes";
import { useEffect, useState } from "react";

const styles = {
  innerRowContainer: {
    display: "flex",
    width: "40%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "25px 0",
  },
  leftText: {
    textAlign: "left",
    width: "40%",
    fontSize: 16,
  },
  rightText: {
    textAlign: "right",
    width: "50%",
    fontSize: 16,
  },
};


const DisplayItemMasters = (props) => {
  const { navigate, location } = props;

  const [rows, setRows] = useState({});

  useEffect(() => {
    props
      .getSingleItem(Number(location.pathname.split("/")[3]))
      .then((res) => {
        if (res.status === 200) {
          let data = res.data
          props
            .getAllUnits()
            .then((res) => {
              if (res.status === 200) {
                let unitData = res.data.results;
                const foundItem = unitData.find(d => d.id === data.uom)?.unit_name
                data.uom = foundItem
                setRows(data)
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  }, []);


  return (
    <Layout>
      <ContentPaper
        bread={
          <div>
            <Bread
              data={[
                {
                  title: "Item Masters",
                  onClick: () => navigate(-1),
                },
                {
                  title: `${rows.name}`,
                },
              ]}
            />
          </div>
        }
      >
        <div style={{ height: "75vh" }}>
          <div style={styles.innerRowContainer}>
            <Typography style={styles.leftText}>Item Name</Typography>
            <Typography style={styles.rightText}>{rows.name}</Typography>
           </div>
          <div style={styles.innerRowContainer}>
            <Typography style={styles.leftText}>Unit</Typography>
            <Typography style={styles.rightText}>{rows.uom}</Typography>
            </div>
          <div style={styles.innerRowContainer}>
            <Typography style={styles.leftText}>Category</Typography>
            <Typography style={styles.rightText}>{rows.category}</Typography>
          </div>
          <div style={styles.innerRowContainer}>
            <Typography style={styles.leftText}>Specifications</Typography>
            <Typography style={styles.rightText}>{rows?.specification || ""}</Typography>
          </div>
          <div style={{ margin: "30px 0" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: 90 }}
              onClick={() => navigate(`${INTERNALROUTES.ITEMMASTERS}/edit/${rows.id}`)}
            >
              Edit
            </Button>
            <Button
              variant="text"
              style={{ marginLeft: 30, color: "#333" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ContentPaper>
      </Layout>
  )
}

export default DisplayItemMasters;