import { Avatar, Button, Divider, Drawer, Typography } from "@mui/material";
import DrawerContents from "./DrawerContents";
import { drawerBackground } from "../constant/colors";
import LogoutIcon from "@mui/icons-material/Logout";
import { ContentBody } from "../constant/colors";
import { useNavigate } from "react-router-dom";
import withAuthentication from "../hoc/withAuthenticate";

const styles = {
  userDiv: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  childrenComponents: {
    width: "100%",

    height: "100vh",
    display: "flex",
    overflowY: "auto",
    paddingTop: "30px",
    paddingLeft: "2%",
    paddingRight: "0px",
    backgroundColor: ContentBody,
  },
};
const drawerWidth = 210;

const Layout = (props) => {
  const navigate = useNavigate();
  // console.log(props);

  return (
    <div style={{ height: "100vh", display: "flex", width: "100%" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: drawerBackground,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <div
          role="button"
          style={{ display: "flex", flexDirection: "column", height: "100vh" }}
        >
          <div style={styles.userDiv}>
            <Avatar
              sx={{ width: 36, height: 36, bgcolor: "#ddd", color: "inherit" }}
            >
              U
            </Avatar>
            <Typography style={{ paddingLeft: 10 }}>User Name</Typography>
          </div>
          <Divider />
          {/* <Button
              startIcon={<MenuIcon />}
              onClick={handleDrawerOpen}
              style={{
                //display: window.innerWidth > 768 && 'none',
                color: '#fff',
                paddingLeft: 24,
                paddingTop: 20
              }}
            /> */}
          <div style={{ flexGrow: 1 }}>
            <DrawerContents {...props} />
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: 45,
            }}
          >
            <Button
              sx={{ color: "#4CB9E7" }}
              startIcon={<LogoutIcon />}
              onClick={() => {
                navigate("/login")
                setTimeout(() => {
                  props.logout()
                },300)
              }}
            >
              Logout
            </Button>
            <span style={{ paddingLeft: 10, color: "#999" }}> v1.0.0 </span>
          </div>
        </div>
      </Drawer>
      <div style={styles.childrenComponents} id="scroll">
        {props.children}
      </div>
    </div>
  );
};

export default withAuthentication(Layout);
