import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import VendorsIndex from "../../components/internal/Vendors";
import DisplayVendors from "../../components/internal/Vendors/DisplayVendors";
import ManageVendors from "../../components/internal/Vendors/ManageVendors";
import axios from "axios";
import APIURL from "../../constant/ApiRoutes";

const VendorsContainer = (props) => {
  const navigate = useNavigate(); // navigate for navigation
  const location = useLocation();

  const getAllVendors = () => {
    return axios.get(APIURL.GET_VENDORS);
  };

  const saveVendor = (data) => {
    return axios.post(APIURL.GET_VENDORS, data);
  };

  const getSingleVendor = (id) => {
    return axios.get(`${APIURL.GET_VENDORS}${id}/`);
  };

  const updateSingleVendor = (id, data) => {
    return axios.patch(`${APIURL.GET_VENDORS}${id}/`, data);
  };

  return (
    <Routes>
      <Route
        path="view/:vendorId"
        element={
          <DisplayVendors
            {...props}
            navigate={navigate}
            location={location}
            getSingleVendor={getSingleVendor}
          />
        }
      />
      <Route
        path="edit/:vendorId"
        element={
          <ManageVendors
            {...props}
            navigate={navigate}
            location={location}
            getSingleVendor={getSingleVendor}
            updateSingleVendor={updateSingleVendor}
          />
        }
      />
      <Route
        path="add"
        element={
          <ManageVendors
            {...props}
            navigate={navigate}
            location={location}
            saveVendor={saveVendor}
          />
        }
      />
      <Route
        path="/"
        element={
          <VendorsIndex
            {...props}
            navigate={navigate}
            location={location}
            getAllVendors={getAllVendors}
          />
        }
      />
    </Routes>
  );
};

export default VendorsContainer;
