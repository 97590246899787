import Layout from "../../../layouts/internalLayout";
import Heading from "../../comman/Heading";

const AllPurchasesIndex = (props) => {
  return (
    <Layout>
      <div>
        <Heading pageTitle={"All Purchases"} />
      </div>
    </Layout>
  );
};

export default AllPurchasesIndex;
