const handleYearValueShown = (addYear) => {
  let today = new Date()
  return today.getFullYear() + addYear
}

const checkEmailAddress = (input) => {
  const regex = /^([a-zA-Z0-9.]+)@([a-zA-Z]+).([a-zA-Z]+)/;
  if (regex.test(input)) {
    return true;
  }
  return false;
};

const getOptions = (user) => {
  return {
    headers: {
      Authorization: `JWT ${user?.token}`,
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
};

export {
  handleYearValueShown,
  checkEmailAddress,
  getOptions,
};