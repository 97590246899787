import HOST from "./host";

const APIURL = {
  HOST,
  // LOGIN
  REGISTER: HOST + "api/credentials/register/",
  LOGIN: HOST + "api/credentials/verify-otp/",

  //INTERNAL
  GET_UNITS : HOST + "api/masters/uoms/",
  GET_ITEMS : HOST + "api/masters/items/",
  GET_SITES : HOST + "api/masters/sites/",
  GET_VENDORS : HOST + "api/masters/vendors/",
  GET_COMPANIES : HOST + "api/masters/companies/",
}

export default APIURL;