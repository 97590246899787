const Heading = ({ pageTitle }) => {
  return (
    <div>
      <span
        style={{
          fontSize: 18,
        }}
      >
        {pageTitle}
      </span>
    </div>
  );
};

export default Heading;
