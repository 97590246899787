import { Button, TextField, Typography } from "@mui/material";
import Layout from "../../../layouts/internalLayout";
import Bread from "../../comman/Bread";
import ContentPaper from "../../comman/ContentPaper";
import { useEffect, useState } from "react";

const ManageSites = (props) => {
  const { navigate, location } = props;

  const [rows, setRows] = useState({
    name: "",
    contact_details: "",
    address: "",
  });

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      props
        .getSingleSite(Number(location.pathname.split("/")[3]))
        .then((res) => {
          if (res.status === 200) {
            setRows(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleChange = (field, value) => {
    setRows((prevValue) => {
      return {
        ...prevValue,
        [field]: value,
      };
    });
  };

  const handleSubmit = (type) => {
    let data = {
      name: rows.name,
      contact_details: rows.contact_details,
      address: rows.address
    };
    if (type === "add") {
      props
        .saveSite(data)
        .then((res) => {
          if (res.status === 201) {
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    } else {
      props
        .updateSingleSite(rows.id, data)
        .then((res) => {
          if (res.status === 200) {
            navigate(-2);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Layout>
      <ContentPaper
        bread={
          <div>
            <Bread
              data={[
                {
                  title: "Sites",
                  onClick: () => navigate(-1),
                },
                {
                  title: `${
                    location.pathname.includes("edit") ? "Edit" : "Add"
                  }`,
                },
              ]}
            />
          </div>
        }
      >
        <div style={{ height: "55vh" }}>
          <div
            style={{
              display: "flex",
              width: "50%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              Site Name
            </Typography>
            <TextField
              size="small"
              value={rows.name || ""}
              onChange={(e) => handleChange("name", e.target.value)}
              style={{ width: 280 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "50%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              Contact No.
            </Typography>
            <TextField
              size="small"
              value={rows.contact_details || ""}
              onKeyPress={(event) => {
                if (event.charCode >= 48 && event.charCode <= 57) {
                  // let it happen, don't do anything
                } else {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 10, minLength: 10 }}
              onChange={(e) => handleChange("contact_details", e.target.value)}
              style={{ width: 280 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "50%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              Address
            </Typography>
            <TextField
              size="small"
              multiline
              minRows={2}
              maxRows={4}
              value={rows.address || ""}
              onChange={(e) => handleChange("address", e.target.value)}
              style={{ width: 280 }}
            />
          </div>
          <div style={{ margin: "30px 0" }}>
            {location.pathname.includes("edit") ? (
              <Button
                variant="contained"
                color="primary"
                disabled={!rows.contact_details || !rows.name || !rows.address}
                onClick={() => handleSubmit("update")}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={!rows.contact_details || !rows.name || !rows.address}
                onClick={() => handleSubmit("add")}
              >
                Submit
              </Button>
            )}
            <Button
              variant="text"
              style={{ marginLeft: 30, color: "#333" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ContentPaper>
    </Layout>
  );
};

export default ManageSites;
