import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AllIndentsIndex from "../../components/internal/AllIndents";
import ManageAllIndents from "../../components/internal/AllIndents/ManageAllIndents";
import DisplayAllIndents from "../../components/internal/AllIndents/DisplayAllIndents";
import axios from "axios";
import APIURL from "../../constant/ApiRoutes";

const AllIndentsContainer = (props) => {
  const navigate = useNavigate(); // navigate for navigation
  const location = useLocation();

  const getAllSites = () => {
    return axios.get(APIURL.GET_SITES);
  };

  const getAllVendors = () => {
    return axios.get(APIURL.GET_VENDORS);
  };

  return (
    <Routes>
      <Route
        path="view/:indentId"
        element={
          <DisplayAllIndents
            {...props}
            navigate={navigate}
            location={location}
          />
        }
      />
      <Route
        path="edit/:indentId"
        element={
          <ManageAllIndents
            {...props}
            navigate={navigate}
            location={location}
          />
        }
      />
      <Route
        path="add"
        element={
          <ManageAllIndents
            {...props}
            navigate={navigate}
            location={location}
          />
        }
      />
      <Route
        path="/"
        element={
          <AllIndentsIndex
            {...props}
            navigate={navigate}
            location={location}
            getAllSites={getAllSites}
            getAllVendors={getAllVendors}
          />
        }
      />
    </Routes>
  );
};

export default AllIndentsContainer;
