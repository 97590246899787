import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ItemMastersIndex from "../../components/internal/ItemMasters";
import DisplayItemMasters from "../../components/internal/ItemMasters/DisplayItemMasters";
import ManageItemMasters from "../../components/internal/ItemMasters/ManageItemMasters";
import APIURL from "../../constant/ApiRoutes";
import axios from "axios";

const ItemMastersContainer = (props) => {
  const navigate = useNavigate(); // navigate for navigation
  const location = useLocation();

  const getAllUnits = () => {
    return axios.get(APIURL.GET_UNITS);
  };

  const getAllItems = () => {
    return axios.get(APIURL.GET_ITEMS);
  };

  const saveItem = (data) => {
    return axios.post(APIURL.GET_ITEMS, data);
  };

  const getSingleItem = (id) => {
    return axios.get(`${APIURL.GET_ITEMS}${id}/`);
  };

  const updateSingleItem = (id, data) => {
    return axios.patch(`${APIURL.GET_ITEMS}${id}/`, data);
  };

  return (
    <Routes>
      <Route
        path="view/:itemId"
        element={
          <DisplayItemMasters
            {...props}
            navigate={navigate}
            location={location}
            getSingleItem={getSingleItem}
            getAllUnits={getAllUnits}
          />
        }
      />
      <Route
        path="edit/:itemId"
        element={
          <ManageItemMasters
            {...props}
            navigate={navigate}
            location={location}
            getSingleItem={getSingleItem}
            getAllUnits={getAllUnits}
            updateSingleItem={updateSingleItem}
          />
        }
      />
      <Route
        path="add"
        element={
          <ManageItemMasters
            {...props}
            navigate={navigate}
            location={location}
            saveItem={saveItem}
            getAllUnits={getAllUnits}
          />
        }
      />
      <Route
        path="/"
        element={
          <ItemMastersIndex
            {...props}
            navigate={navigate}
            location={location}
            getAllItems={getAllItems}
            getAllUnits={getAllUnits}
          />
        }
      />
    </Routes>
  );
};

export default ItemMastersContainer;
