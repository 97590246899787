const INTERNALROUTES = {
  DASHBOARD: "/internalDashboard",
  ALLINDENTS: "/internalAllIndents",
  ALLPURCHASES: "/internalAllPurchases",
  SITETRANSFERS: "/internalSiteTransfers",
  SITES: "/internalSites",
  VENDORS: "/internalVemdors",
  ITEMMASTERS: "/internalItemMasters",
  UNITMASTERS: "/internalUnitMasters",
  COMPANYMASTERS: "/internalCompanyMasters",
  USERPERMISSIONS: "/internalUserPermissions"  
}

export default INTERNALROUTES;