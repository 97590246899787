import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import axios from "axios";
import withMUI from "./hoc/withMUI";
import DashBoardContainer from "./containers/internal/dashBoard";
import INTERNALROUTES from "./constant/internalRoutes";
import AllIndentsContainer from "./containers/internal/allIndents";
import AllPurchasesContainer from "./containers/internal/allPurchases";
import SiteTransfersContainer from "./containers/internal/siteTransfers";
import SiteContainers from "./containers/internal/sites";
import VendorsContainer from "./containers/internal/vendors";
import ItemMastersContainer from "./containers/internal/itemMasters";
import CompanyMastersContainer from "./containers/internal/companyMasters";
import UserPermissionsContainer from "./containers/internal/userPermissions";
import UnitMastersContainer from "./containers/internal/unitMasters";
import LoginIndex from "./containers/comman/loginIndex";
import withUser from "./hoc/withUser";
import withAuthentication from "./hoc/withAuthenticate";

const ProtectedRoute = ({ user, children, redirectPath = "/login" }) => {
  // console.log(!user?.success)
  if (!user?.success) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

function App(props) {
  console.log(props);
  props.user &&
    (axios.defaults.headers.common[
      "Authorization"
    ] = `JWT ${props.user.token}`);
  axios.defaults.headers.common["Content-Type"] =
    "application/json;charset=UTF-8";

  return (
    <Router>
      <Routes>
        <Route
          path={`${INTERNALROUTES.DASHBOARD}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <DashBoardContainer {...props} />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${INTERNALROUTES.ALLINDENTS}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <AllIndentsContainer {...props} />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${INTERNALROUTES.ALLPURCHASES}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <AllPurchasesContainer {...props} />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${INTERNALROUTES.SITETRANSFERS}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <SiteTransfersContainer {...props} />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${INTERNALROUTES.SITES}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <SiteContainers {...props} />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${INTERNALROUTES.VENDORS}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <VendorsContainer {...props} />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${INTERNALROUTES.ITEMMASTERS}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <ItemMastersContainer {...props} />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${INTERNALROUTES.UNITMASTERS}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <UnitMastersContainer {...props} />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${INTERNALROUTES.COMPANYMASTERS}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <CompanyMastersContainer {...props} />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${INTERNALROUTES.USERPERMISSIONS}/*`}
          element={
            <ProtectedRoute user={props.user}>
              <UserPermissionsContainer {...props} />
            </ProtectedRoute>
          }
        />

        <Route path="/login" element={<LoginIndex {...props} />} />

        <Route
          path="/"
          element={
            props.user?.success ? (
              <Navigate to={`${INTERNALROUTES.DASHBOARD}`} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default withAuthentication(withUser(withMUI(App)));
