import { useState } from "react";
import Layout from "../../../layouts/internalLayout";
import Bread from "../../comman/Bread";
import ContentPaper from "../../comman/ContentPaper";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Divider,
  Typography,
} from "@mui/material";
import { handleYearValueShown } from "../../../method";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { drawerSelectedColor } from "../../../constant/colors";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const styles = {
  title: {
    fontSize: 18,
    color: drawerSelectedColor,
    fontWeight: 500,
  },
};

const financialYears = [
  `${handleYearValueShown(-1)}-${handleYearValueShown(0)}`,
  `${handleYearValueShown(0)}-${handleYearValueShown(1)}`,
  `${handleYearValueShown(1)}-${handleYearValueShown(2)}`,
];

const sites = ["Site A", "Site B"];
const vendors = ["Vendor X", "Vendor Y"];
const items = ["TMT BAR (F) FE 550", "TMT BAR 10MM", "GH Wire"];
const uoms = ["Unit 1", "Unit 2", "Unit 3", "Unit 4"];

const ItemRow = ({
  formData,
  handleItemChange,
  handleDeleteItemButton,
  index,
  noOfItemRow,
}) => {
  return (
    <Grid
      container
      spacing={2}
      style={{ margin: "10px 0" }}
      alignItems={"center"}
    >
      <Grid item xs={12} md={3}>
        <Autocomplete
          size="small"
          options={items}
          value={formData.selectedItems[index]?.item || []}
          onChange={(e, newValue) => handleItemChange(index, "item", newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Items" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          size="small"
          label="Quantity"
          value={formData.selectedItems[index]?.quantity || ""}
          onChange={(e) => handleItemChange(index, "quantity", e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          multiple
          size="small"
          limitTags={2}
          options={uoms}
          value={formData.selectedItems[index]?.uom || []}
          onChange={(e, newValue) => handleItemChange(index, "uom", newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Unit" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          size="small"
          label="Remark"
          // style={{ width: 200 }}
          value={formData.selectedItems[index]?.remark || ""}
          onChange={(e) => handleItemChange(index, "remark", e.target.value)}
          fullWidth
        />
      </Grid>
      {noOfItemRow > 1 && (
        <Grid item xs={2} md={1} style={{ paddingLeft: 8 }}>
          <Button
            startIcon={<DeleteOutlinedIcon />}
            variant="text"
            style={{ color: "#333", width: 80 }}
            onClick={() => handleDeleteItemButton(index)}
          />
        </Grid>
      )}
    </Grid>
  );
};

const ManageAllIndents = (props) => {
  const [noOfItemRow, setNoOfItemRow] = useState(1);

  const [formData, setFormData] = useState({
    indentDate: new Date(),
    requiredDate: null,
    selectedFinancialYear: `${handleYearValueShown(0)}-${handleYearValueShown(
      1
    )}`,
    selectedSite: null,
    selectedVendor: null,
    selectedItems: [
      {
        item: null,
        quantity: "",
        uom: [],
        remark: "",
      },
    ],
    comment: "",
  });

  const handleChange = (field) => (event, value) => {
    console.log(field, event, value);
    if (field !== "requiredDate" || field !== "indentDate")
      setFormData((prevData) => ({
        ...prevData,
        [field]: value ?? event.target.value,
      }));
  };

  const handleDateChange = (field, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleItemChange = (index, key, value) => {
    let allItems = [...formData.selectedItems];
    allItems[index][key] = value;

    setFormData((prevData) => ({
      ...prevData,
      selectedItems: allItems,
    }));
  };

  const handleAddItemButton = () => {
    let totalNoOfItemsRow = noOfItemRow + 1;
    let allItems = [...formData.selectedItems];
    allItems.push({
      item: null,
      quantity: "",
      uom: [],
      remark: "",
    });
    setFormData((prevData) => ({
      ...prevData,
      selectedItems: allItems,
    }));
    setNoOfItemRow(totalNoOfItemsRow);
  };

  const handleDeleteItemButton = (indexToRemove) => {
    let totalNoOfItemsRow = noOfItemRow - 1;
    let allItems = [...formData.selectedItems];

    allItems.splice(indexToRemove, 1);
    setFormData((prevData) => ({
      ...prevData,
      selectedItems: allItems,
    }));
    setNoOfItemRow(totalNoOfItemsRow);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  const { navigate, location } = props;

  return (
    <Layout>
      <ContentPaper
        bread={
          <div>
            <Bread
              data={[
                {
                  title: "All Indents",
                  onClick: () => navigate(-1),
                },
                {
                  title: `${
                    location.pathname.includes("edit") ? "Edit" : "Add"
                  }`,
                },
              ]}
            />
          </div>
        }
      >
        <Box component="form" onSubmit={handleSubmit} sx={{ pr: 2, pb: 2 }}>
          <Typography style={styles.title}> Basic Detail </Typography>
          <Grid container spacing={2} style={{ margin: "10px 0 20px 0" }}>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Indent Date"
                  value={formData.indentDate}
                  onChange={(newValue) =>
                    handleDateChange("indentDate", newValue.toDate())
                  }
                  minDate={new Date()}
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={financialYears}
                value={formData.selectedFinancialYear}
                disabled
                onChange={handleChange("selectedFinancialYear")}
                renderInput={(params) => (
                  <TextField {...params} label="Financial Year" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={sites}
                value={formData.selectedSite}
                onChange={handleChange("selectedSite")}
                renderInput={(params) => (
                  <TextField {...params} label="Site" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={vendors}
                value={formData.selectedVendor}
                onChange={handleChange("selectedVendor")}
                renderInput={(params) => (
                  <TextField {...params} label="Vendor" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Required Date"
                  value={formData.requiredDate}
                  onChange={(newValue) => {
                    handleDateChange("requiredDate", newValue.toDate());
                  }}
                  inputFormat="DD-MM-YYYY"
                  minDate={formData.indentDate}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth readOnly />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Comment"
                value={formData.comment}
                onChange={handleChange("comment")}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
          <Typography style={styles.title}> Items Detail </Typography>
          {[...Array(noOfItemRow).keys()].map((components, index) => {
            return (
              <ItemRow
                formData={formData}
                handleItemChange={handleItemChange}
                handleDeleteItemButton={handleDeleteItemButton}
                index={index}
                noOfItemRow={noOfItemRow}
              />
            );
          })}
          <Grid item xs={4} md={3} style={{ paddingLeft: 16, paddingTop: 16 }}>
            <Button
              startIcon={<AddIcon />}
              variant="text"
              style={{ border: "1px dotted #333", color: "#333" }}
              onClick={() => handleAddItemButton()}
            >
              Add Item
            </Button>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ margin: "20px 0" }}
          >
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
            <Button
              variant="text"
              style={{ marginLeft: 30, color: "#333" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </ContentPaper>
    </Layout>
  );
};

export default ManageAllIndents;
