import { Button, Typography } from "@mui/material";
import INTERNALROUTES from "../../../constant/internalRoutes";
import Layout from "../../../layouts/internalLayout"
import Bread from "../../comman/Bread"
import ContentPaper from "../../comman/ContentPaper"
import { useEffect, useState } from "react";

const DisplayVendors = (props) => {
  const { navigate, location } = props;

  const [rows, setRows] = useState({});

  useEffect(() => {
    props
      .getSingleVendor(Number(location.pathname.split("/")[3]))
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Layout>
      <ContentPaper
        bread={
          <div>
            <Bread
              data={[
                {
                  title: "Vendors",
                  onClick: () => navigate(-1),
                },
                {
                  title:  `${rows.name}`,
                },
              ]}
            />
          </div>
        }
      >
        <div style={{ height: "55vh" }}>
          <div
            style={{
              display: "flex",
              width: "50%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              Vendor Name
            </Typography>
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              {rows.name}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              width: "50%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              Contact No.
            </Typography>
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              {rows.contact_details}
            </Typography>
          </div>
          <div style={{ margin: "30px 0" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: 90 }}
              onClick={() =>
                navigate(`${INTERNALROUTES.VENDORS}/edit/${rows.id}`)
              }
            >
              Edit
            </Button>
            <Button
              variant="text"
              style={{ marginLeft: 30, color: "#333" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ContentPaper>
      </Layout>
  )
}

export default DisplayVendors;