import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import Layout from "../../../layouts/internalLayout";
import Bread from "../../comman/Bread";
import ContentPaper from "../../comman/ContentPaper";
import { useEffect, useState } from "react";
import { handleYearValueShown } from "../../../method";

const ManageCompanyMasters = (props) => {
  const { navigate, location } = props;

  const financialYears = [
    `${handleYearValueShown(-1)}-${handleYearValueShown(0)}`,
    `${handleYearValueShown(0)}-${handleYearValueShown(1)}`,
    `${handleYearValueShown(1)}-${handleYearValueShown(2)}`,
  ];

  const [rows, setRows] = useState({
    name: "",
    gst_number: "",
    financial_years: "",
  });

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      props
        .getSingleCompanyMaster(Number(location.pathname.split("/")[3]))
        .then((res) => {
          if (res.status === 200) {
            setRows(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleChange = (field, value) => {
    console.log(value);
    setRows((prevValue) => {
      return {
        ...prevValue,
        [field]: value,
      };
    });
  };

  const handleSubmit = (type) => {
    let data = {
      name: rows.name,
      gst_number: rows.gst_number,
      financial_years: rows.financial_years,
    };
    if (type === "add") {
      props
        .saveCompanyMaster(data)
        .then((res) => {
          if (res.status === 201) {
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    } else {
      props
        .updateSingleCompanyMaster(rows.id, data)
        .then((res) => {
          if (res.status === 200) {
            navigate(-2);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Layout>
      <ContentPaper
        bread={
          <div>
            <Bread
              data={[
                {
                  title: "Company Masters",
                  onClick: () => navigate(-1),
                },
                {
                  title: `${
                    location.pathname.includes("edit") ? "Edit" : "Add"
                  }`,
                },
              ]}
            />
          </div>
        }
      >
        <div style={{ height: "55vh" }}>
          <div
            style={{
              display: "flex",
              width: "50%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              Company Name
            </Typography>
            <TextField
              size="small"
              value={rows.name || ""}
              onChange={(e) => handleChange("name", e.target.value)}
              style={{ width: 280 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "50%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              GST No.
            </Typography>
            <TextField
              size="small"
              value={rows.gst_number || ""}
              onChange={(e) => handleChange("gst_number", e.target.value)}
              style={{ width: 280 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "50%",
              alignItems: "flex-start",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 16,
              }}
            >
              Financial Years
            </Typography>
            <Autocomplete
              options={financialYears}
              value={rows.financial_years}
              size="small"
              onChange={(e, newValue) =>
                handleChange("financial_years", newValue)
              }
              renderInput={(params) => (
                <TextField {...params} style={{ width: 280 }} />
              )}
            />
          </div>
          <div style={{ margin: "30px 0" }}>
            {location.pathname.includes("edit") ? (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !rows.gst_number || !rows.name || !rows.financial_years
                }
                onClick={() => handleSubmit("update")}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !rows.gst_number || !rows.name || !rows.financial_years
                }
                onClick={() => handleSubmit("add")}
              >
                Submit
              </Button>
            )}
            <Button
              variant="text"
              style={{ marginLeft: 30, color: "#333" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ContentPaper>
    </Layout>
  );
};

export default ManageCompanyMasters;
